.header {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 5px 3px 20px;
    background-color: #f8f9fa;
}

.logo {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    font-style: italic;
    color: #343a40;
}
