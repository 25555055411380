.chart-container {
    position: relative;
    width: 100%; /* Ensure the chart-container takes up the full width of the .container */
}

.line-path {
    fill: none;
    stroke: #007bff;
    stroke-width: 1.2;
}

.title-text {
    font-size: 12px;
    font-weight: bold;
    text-anchor: start;
}

.title-background {
    fill: rgba(184,184,184,0.15);
    rx: 2;
    ry: 2;
}

.plus-background {
    fill: rgba(184,184,184,0.15);
    rx: 2;
    ry: 2;
}

.plus-group-text {
    font-size: 12px;
    font-weight: bold;
    text-anchor: middle; /* SVG-specific, usually set in SVG */
}

.select-box {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0px;
    width: 360px;
    z-index: 10;
}

.select-box-title {
    margin: 10px;
    text-align: left;
}

.select-box-input {
    width: 100%;
    padding: 8px 8px 8px 14px;
    box-sizing: border-box;
    margin-bottom: 5px;
    border: none;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    outline: none;
}

.select-box-list {
    list-style: none;
    padding: 0;
    font-size: 13px;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.select-box-item {
    padding: 9px 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.select-box-item:hover {
    background-color: #f0f0f0;
}

.select-box-item-last {
    border-radius: 0 0 8px 8px;
}

.ticker-symbol {
    font-weight: bold;
    margin-left: 5px;
}

.ticker-exchange {
    font-size: 11px;
    margin-right: 5px;
}

{/* TIMEFRAME SELECTOR */}
.timeframe-selector {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px; /* Add some space between the selector and chart */
}

.timeframe-option {
    padding: 3px 4px;
    margin-right: 4px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    background-color: transparent; /* Default background */
    transition: background-color 0.2s ease;
}

.timeframe-option:hover {
    background-color: #f0f0f0; /* Grey background on hover for non-active */
}

.timeframe-option.active {
    background-color: #007bff; /* Regular blue background for active timeframe */
    color: white; /* Ensure text is visible on blue background */
}

.timeframe-option.active:hover {
    background-color: #0056b3; /* Slightly darker blue on hover when active */
}