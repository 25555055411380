* {
    margin: 0;
    padding: 0;
}

html, body  {
    font-family: 'Poppins', sans-serif;
    background-color: #f8f9fa;
    color: #343a40;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1; /* Take up the rest of the screen */
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    margin: 0;
    padding: 0;
}

.App {
    width: 100%;
    padding: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Take up the rest of the screen */
}

.container {
    width: 100%;
    margin: 0px auto;
    padding-top: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Take up the rest of the screen */
}

h1 {
    margin: 0;
}

.loading-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1; /* Fills the remaining space below the header */
}

.loading-container p {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
    font-family: Arial, sans-serif;
}

.drag-handle {
    cursor: move;
}

.react-grid-item {
    border-bottom: 1px solid #7e7e7e; /* Thin line between boxes */
}

.react-resizable-handle {
    scale: 85%;
    width: 10px;
    height: 10px;
    bottom: 0; /* Resize from bottom only */
    right: 50%;
    cursor: ns-resize; /* Cursor for bottom resizing */
}
